import { useHistory } from 'react-router-dom';

export const useLogoNavigation = () => {
    const history = useHistory();

    const navigateToHome = () => {
        history.push('/home');
    };

    return {
        navigateToHome,
    };
};
