import Lottie from 'react-lottie';
import styled from 'styled-components';

import { SPACING } from '../../constants/theme';

export const LOADING_STYLES = {
	sizes: {
		small: '40px',
		medium: '70px'
	}
};

export const Loading = styled.div`
	width: 100%;
	align-self: center;
	padding: ${SPACING.large} 0;
`;

Loading.Animation = styled(Lottie)`
	width: 48px;
	height: 48px;
`;
