import styled from 'styled-components';

export const OTPValidationForm = {};

OTPValidationForm.InputFieldContainer = styled.div`
    margin-bottom: 24px;

    label {
        margin-bottom: 8px;
        display: block;
        font-size: 16px;
        font-weight: bold;

        &::after {
            content: '*';
            color: red;
            margin-left: 4px;
        }
    }
`;

OTPValidationForm.HeaderContainer = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

OTPValidationForm.HeaderBodyContainer = styled.div`
    display: flex;
    align-items: center;
`;

OTPValidationForm.ValidationButton = styled.button`
    background: transparent !important;
    border: none !important;
    color: #0088FF !important;
`;
