import React from 'react';

import { CardInfo } from '../../../components';

const Doctor = ({ doctor, withoutAvatar }) => {
	if (!doctor) {
		return null;
	}

	return (
		<CardInfo
			withoutAvatar={withoutAvatar}
			withoutIcon
			thumbUrl={doctor.file ? doctor.file.thumb_url : null}
			title={doctor.name}
			subtitle={doctor.specialty}
			subtitleIcon="user-doctor"
		/>
	)
};

export default Doctor;
