import React from "react";
import { ScheduledPage as StyledScheduledPage } from "./ScheduledSucessfully.styles.js";
import { DateUtils, AddressUtils } from "../../utils/index.js";
import { Loading, Button } from '../../components';
import { DoctorItem } from '../';
import { Icon } from '@amigoapp/doca-react';

const ICON_BY_GROUPS = {
	TELEMEDICINE: 'video',
	ATTENDANCE: 'location-dot',
	COMEBACK: 'location-dot'
};

const ATTENDANCE_STATUS = {
	CONFIRMED: 'CONFIRMED',
	CANCELED: 'CANCELED'
};

function ScheduledSucessfully({
	loading,
	attendance,
	attendanceStatus,
	handleEditAttendancePress,
	handleSaveAgendaPress,
	variant,
	handleDirectionPress
}) {

	if (loading) {
		return <Loading />;
	}
	return (
		<StyledScheduledPage padded>
			<StyledScheduledPage.Body>
				<StyledScheduledPage.CardContainer >
					<StyledScheduledPage.HeaderContainer>
						<div>
							<i className={`doca-icon doca-icon__circle-check doca-icon--extra-large doca-icon--solid doca-mb-4 doca-text--${variant}`} style={{ width: 64, height: 64 , fontSize: 64}} />
						</div>
						<StyledScheduledPage.Text size="large" weight="semibold">
							{attendanceStatus === ATTENDANCE_STATUS.CONFIRMED
								? "Agendamento realizado"
								: "Agendamento cancelado"}
						</StyledScheduledPage.Text>

						<StyledScheduledPage.Text marginTop={4} size="small" variant="gray">
							Em breve você receberá um SMS com todas as informações do agendamento.
						</StyledScheduledPage.Text>
					</StyledScheduledPage.HeaderContainer>

					<hr className="doca-separator doca-separator--large" />

					<StyledScheduledPage.ContainerWrapper>
						<StyledScheduledPage.InfoSection>
							<StyledScheduledPage.IconLabelWrapper>
								<i className="doca-icon doca-icon__calendar-lines" />
								<StyledScheduledPage.Label>Data</StyledScheduledPage.Label>
							</StyledScheduledPage.IconLabelWrapper>
							<StyledScheduledPage.Content>
								{DateUtils.formatTime(
									attendance?.start_date,
									attendance?.arrival_start_time,
									"dddd DD, MMM YYYY • HH:mm"
								)} às {DateUtils.formatTime(
									attendance?.end_date,
									attendance?.arrival_end_time,
									"HH:mm"
								)}
							</StyledScheduledPage.Content>
						</StyledScheduledPage.InfoSection>
					</StyledScheduledPage.ContainerWrapper>

					<StyledScheduledPage.ContainerWrapper onClick={handleDirectionPress} style={{ cursor: 'pointer' }}>
						<StyledScheduledPage.InfoSection>
							<StyledScheduledPage.IconLabelWrapper>
								<i className={`doca-icon doca-icon__${ICON_BY_GROUPS[attendance?.event_group?.type]}`} />
								<StyledScheduledPage.Label>Endereço</StyledScheduledPage.Label>
							</StyledScheduledPage.IconLabelWrapper>
							<StyledScheduledPage.AddressContainer>
								<StyledScheduledPage.Content>
									{attendance?.place?.name && attendance?.place?.address ? (
										<>
											{attendance?.place?.name} • {AddressUtils.formatAddress(attendance?.place)}
										</>
									) : (
										"Não informado"
									)}
								</StyledScheduledPage.Content>
							</StyledScheduledPage.AddressContainer>
						</StyledScheduledPage.InfoSection>
						<Icon
							className="doca-text--extra-large doca-pl-4"
							color={variant}
							name="diamond-turn-right"
						/>
					</StyledScheduledPage.ContainerWrapper>


					<StyledScheduledPage.ContainerWrapper>
						<StyledScheduledPage.InfoSection>
							<StyledScheduledPage.IconLabelWrapper>
								<i className="doca-icon doca-icon__stethoscope" />
								<StyledScheduledPage.Label>Tipo de atendimento</StyledScheduledPage.Label>
							</StyledScheduledPage.IconLabelWrapper>
							<StyledScheduledPage.Content>
								{attendance?.event?.preview_name || attendance?.event?.name}
							</StyledScheduledPage.Content>
						</StyledScheduledPage.InfoSection>
					</StyledScheduledPage.ContainerWrapper>

					<StyledScheduledPage.ContainerWrapper>
						<StyledScheduledPage.InfoSection>
							<StyledScheduledPage.IconLabelWrapper>
								<i className={attendance?.insurance?.id ? "doca-icon doca-icon__hospital" : "doca-icon doca-icon__credit-card"} />
								<StyledScheduledPage.Label>Plano</StyledScheduledPage.Label>
							</StyledScheduledPage.IconLabelWrapper>
							<StyledScheduledPage.Content>
								{attendance?.insurance?.id ? (
									<>Plano - {attendance?.insurance?.name}</>
								) : (
									"Particular"
								)}
							</StyledScheduledPage.Content>
						</StyledScheduledPage.InfoSection>
					</StyledScheduledPage.ContainerWrapper>

					<DoctorItem doctor={attendance?.user} />

					<StyledScheduledPage.FlexContainer>
						<Button
							block
							size="large"
							marginBottom={16}
							variant={variant}
							onClick={handleSaveAgendaPress}
						>
							<i className="doca-icon doca-icon__calendar-plus"></i>
							Salvar na agenda
						</Button>

						<Button
							block
							size="large"
							onClick={handleEditAttendancePress}
							disabled={!attendance.allowedToEdit}
							variant="secondary"
							type="button"
						>
							<i className="doca-icon doca-icon__pen-to-square"></i>
							Editar agendamento
						</Button>
					</StyledScheduledPage.FlexContainer>
				</StyledScheduledPage.CardContainer>
			</StyledScheduledPage.Body>
		</StyledScheduledPage>
	);
};

export default ScheduledSucessfully;
