import styled from 'styled-components';

export const RemainingTimeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const RemainingTimeLabel = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  width: 126px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 6px 0;
  font-size: 12px;
  font-weight: bold;
`;
