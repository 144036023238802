import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanyContext } from '../../contexts/company';
import { DateUtils, NavigationUtils, WindowDimensionsUtils } from '../../utils';
import { list } from '../../processes/calendar';
import { listEvents } from '../../processes/agenda-event';
import { createAttendance } from '../../processes/attendance';
import SelectTimegridForm from './SelectTimegridForm';
import moment from 'moment';
import { showErrorToast } from '@amigoapp/doca-react';

const footerPlusHeaderHeight = 344;

function SelectTimegridFormContainer() {
	const history = useHistory();
	const { form, settings, setInfo, scheduleSettings } = useCompanyContext();
	const dimensions = WindowDimensionsUtils.useWindowDimensions();

	// Estados
	const [loading, setLoading] = useState(true);
	const [loadingTimegrid, setLoadingTimegrid] = useState(false);
	const [slotsByDate, setSlotsByDate] = useState([]);
	const [enabledDates, setEnabledDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState([]);
	const [currentSlotsByUser, setCurrentSlotsByUser] = useState([]);
	const [eventId, setEventId] = useState(form?.event?.id);
	const [placeId, setPlaceId] = useState(form?.place?.id);
	const [selectedSlot, setSelectedSlot] = useState(form?.slot);
	const [events, setEvents] = useState([]);

	// Calcula altura máxima da lista
	const listMaxHeight = useMemo(() => (!dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight), [dimensions?.height]);

	/**
	 * Função: Buscar eventos
	 * - Recupera os eventos disponíveis para o agendamento
	 */
	const getEvents = useCallback(async () => {
		setLoading(true);

		const filter = {
			insurance_id: form?.insurance?.id,
			scheduler_event_group_id: form?.event_group?.id
		};

		if (!filter.scheduler_event_group_id) {
			setLoading(false);
			return;
		}

		const { data, error } = await listEvents(filter);

		if (error) {
			showErrorToast('Não foi possível buscar os eventos.');
			setLoadingTimegrid(false);
			return;
		}

		setEvents(data);
		setLoading(false);
	}, [form?.insurance?.id, form?.event_group?.id]);

	/**
	 * Função: Buscar calendário e horários disponíveis
	 * - Recupera os slots e datas habilitadas para o agendamento
	 */
	const getCalendarAndSlots = useCallback(
		async (updatedForm) => {
			const filter = {
				event_id: updatedForm.event?.id,
				place_id: updatedForm.place?.id,
				insurance_id: updatedForm.insurance?.id,
				patient_id: updatedForm?.patient?.id
			};

			if (!filter.event_id || !filter.place_id) return;

			setLoadingTimegrid(true);
			const { data, error } = await list(filter);

			if (error) {
				showErrorToast('Não foi possível buscar os horários disponíveis.');
			}

			const { datesEnabled, currentDateSlotsByUser, closestSelectedDate } = (data || []).reduce(
				(acc, info) => {
					if (DateUtils.isToday(info.date)) return acc;
					if (!acc.closestSelectedDate && info.status === 'AVAILABLE') {
						acc.closestSelectedDate = info.date;
						acc.currentDateSlotsByUser = info.slotsByUser;
					}
					if (info.status === 'AVAILABLE') {
						acc.datesEnabled.push(info.date);
					}
					return acc;
				},
				{
					datesEnabled: [],
					currentDateSlotsByUser: {},
					closestSelectedDate: null
				}
			);

			const selectedDate = closestSelectedDate || moment().format('YYYY-MM-DD');
			setSlotsByDate(data);
			setEnabledDates(datesEnabled);
			setCurrentSlotsByUser(currentDateSlotsByUser);
			setSelectedDate(selectedDate);
			setLoadingTimegrid(false);
		},
		[]
	);

	const updateForm = useCallback((newInfo) => {
		setInfo((prevInfo) => {
			const updatedForm = { ...prevInfo.form, ...newInfo };
			return {
				...prevInfo,
				form: updatedForm
			};
		});
	}, [setInfo]);

	// useEffect para carregar eventos ao inicializar o componente apenas uma vez
	useEffect(() => {
		getEvents();
	}, [getEvents]);

	// useEffect para buscar o calendário e slots novamente ao carregar a página
	useEffect(() => {
		getCalendarAndSlots(form); // Use the current form state to fetch calendar and slots
	}, [form, getCalendarAndSlots]);
	/**
	 * Função: Submeter agendamento
	 * - Cria um novo atendimento com base no slot selecionado
	 */
	const handleSubmit = useCallback(async () => {
		const start = moment(selectedSlot.start, 'HH:mm');
		const end = moment(selectedSlot.end, 'HH:mm');

		const attendanceData = {
			event_id: form.event?.id,
			place_id: form.place?.id,
			patient_id: form.patient?.id,
			insurance_id: form.insurance?.id,
			start_date: moment(selectedDate).hour(start.format('HH')).minute(start.format('mm')),
			end_date: moment(selectedDate).hour(end.format('HH')).minute(end.format('mm')),
			user_id: selectedSlot.user_id
		};

		if (!form?.patient?.id) {
			setInfo((values) => ({
				...values,
				form: {
					...values.form,
					slot: selectedSlot,
					start_date: attendanceData.start_date,
					end_date: attendanceData.end_date
				}
			}));

			return NavigationUtils.navigate(history, '/patient-form');
		}

		setLoading(true);
		const { data, error } = await createAttendance(attendanceData);
		setLoading(false);

		if (error) {
			showErrorToast('Algo de errado aconteceu ao criar o agendamento.');
		} else {
			setInfo((values) => ({
				...values,
				form: {
					...values.form,
					slot: selectedSlot,
					start_date: attendanceData.start_date,
					end_date: attendanceData.end_date
				},
				externalPagesToken: data.external_pages_token
			}));

			return NavigationUtils.navigate(history, '/scheduled-succesfully');
		}
	}, [history, setInfo, selectedSlot, form.event?.id, form.place?.id, form.patient?.id, form.insurance?.id, selectedDate]);

	/**
	 * Função: Selecionar data
	 * - Atualiza os slots e o estado de data selecionada
	 */
	const handleSelectDate = useCallback(
		(date) => {
			setLoading(true);
			setSelectedDate(date);

			const dateInfoSlots = slotsByDate.find((item) => item.date === date);
			setCurrentSlotsByUser(dateInfoSlots?.slotsByUser || []);
			setLoading(false);
		},
		[setLoading, setCurrentSlotsByUser, slotsByDate, setSelectedDate]
	);

	/**
	 * Função: Navegar para a tela de seleção de eventos
	 * - Limpa os dados do formulário e navega de volta
	 */
	const handleNavigateBackPress = useCallback(() => {
		setInfo((values) => ({
			...values,
			form: {
				...values.form,
				slot: null,
				start_date: null,
				end_date: null,
				place: null,
				event: null
			}
		}));

		return NavigationUtils.navigate(history, '/select-event-group-payment-method');
	}, [history, setInfo]);

	/**
	 * Função: Selecionar evento
	 * - Atualiza o evento selecionado e busca os horários disponíveis
	 */
	const handleSelectEvent = useCallback((eventId) => {
		setEventId(eventId);
		updateForm({ event: scheduleSettings.events.find((item) => item.id === ~~eventId) });
	}, [scheduleSettings.events, updateForm]);

	/**
	 * Função: Selecionar lugar
	 * - Atualiza o lugar selecionado e busca os horários disponíveis
	 */
	const handlePlaceSelect = useCallback((placeId) => {
		setPlaceId(placeId);
		updateForm({ place: scheduleSettings.places.find((item) => item.id === ~~placeId) });
	}, [scheduleSettings.places, updateForm]);

	// Recupera o lugar selecionado
	const selectedPlace = useMemo(
		() => (placeId ? scheduleSettings?.places.find((place) => place.id === ~~placeId) : null),
		[placeId, scheduleSettings?.places]
	);

	return (
		<SelectTimegridForm
			loading={loading}
			loadingTimegrid={loadingTimegrid}
			selectedSlot={selectedSlot}
			listMaxHeight={listMaxHeight}
			selectedDate={selectedDate}
			variant={settings.theme?.name}
			enabledDates={enabledDates}
			onSelectDate={handleSelectDate}
			currentSlotsByUser={currentSlotsByUser}
			onSubmitPress={handleSubmit}
			onSelectSlot={setSelectedSlot}
			handleNavigateBackPress={handleNavigateBackPress}
			submitText={form?.patient?.id ? 'Realizar agendamento' : 'Próximo'}
			patient={form?.patient}
			selectedEventId={eventId || form?.event?.id}
			onSelectEvent={handleSelectEvent}
			events={events}
			selectedPlaceId={placeId || form?.place?.id}
			onSelectPlace={handlePlaceSelect}
			places={scheduleSettings?.places}
			selectedPlace={selectedPlace}
		/>
	);
}

export default SelectTimegridFormContainer;
