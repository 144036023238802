import React, { useCallback } from 'react';

import { SlotItem as StyledSlotItem } from '../SlotItem/SlotItem.styles';

import { DoctorItem } from '../../';

const ShiftSlotItem = ({
	user,
	variant,
	slotsGrouped,
	selectedSlot,
	onSelectSlot,
}) => {
	const isSlotActive = useCallback(slot => slot.id === selectedSlot?.id, [selectedSlot]);

	const renderShiftButton = useCallback(slot => (
		<button key={slot.id} className={`ds-flex ds-flex__item doca-shortcut doca-shortcut--small doca-justify-center w-100 ${isSlotActive(slot) ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectSlot({ ...slot, user })}>
			{slot.label}
		</button>
	), [variant, user, isSlotActive, onSelectSlot]);

    return (
        <StyledSlotItem className="doca-mb-4">
            <DoctorItem doctor={user} />
				<StyledSlotItem.SlotContainer className="doca-mt-4">
					<StyledSlotItem.SlotContainer.Info className="doca-gap-4">
					<StyledSlotItem.SlotContainer.Info.Turn weight="medium">Turno de preferência • Ordem de chegada</StyledSlotItem.SlotContainer.Info.Turn>
						<StyledSlotItem.SlotContainer.Info.Slots className="doca-slots__container">
							{slotsGrouped.map((slot, index) => renderShiftButton(slot))}
						</StyledSlotItem.SlotContainer.Info.Slots>
					</StyledSlotItem.SlotContainer.Info>
				</StyledSlotItem.SlotContainer>
        </StyledSlotItem>
    );
};

export default ShiftSlotItem;
