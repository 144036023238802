import React, { useCallback } from 'react';
import { Form as StyledForm } from './PatientNextAttendances.styles.js';
import { Container, Button, Loading } from '../../components';
import { AttendanceItem } from '../../views';

function PatientNextAttendances({
	variant,
	handleScheduleAttendance,
	patient,
	attendances,
	listMaxHeight,
	handleEditAttendancePress,
	handleCancelAttendancePress,
	loading
}) {
	const renderAttendanceItem = useCallback(
		(attendance) => (
			<div className="doca-mb-6" key={attendance.id}>
				<AttendanceItem
					attendance={attendance}
					onEditClick={handleEditAttendancePress}
					onCancelClick={handleCancelAttendancePress}
					remainingDaysInfo={attendance.remainingDaysInfo}
				/>
			</div>
		),
		[handleEditAttendancePress, handleCancelAttendancePress]
	);

	if (loading) {
		return <Loading />;
	}

	return (
		<Container padded>
			<StyledForm.Header.Title
				size="extra-large"
				weight="medium"
				style={{ color: '#26282A' }}
			>
				Olá, {patient.name}
			</StyledForm.Header.Title>

			<StyledForm.Header.Subtitle
				size="small"
				weight="medium"
				marginTop={8}
				style={{ color: '#6b6b6b' }}
			>
				Visualize o seus agendamentos ou realize um novo agendamento.
			</StyledForm.Header.Subtitle>

			<div className="doca-mt-6">
				{attendances?.length > 0 ? (
					<StyledForm.FlatList
						data={attendances}
						renderItem={renderAttendanceItem}
						handleKeyExtractor={(item) => item.id}
						maxHeight={listMaxHeight}
					/>
				) : (
					<div className="doca-alert doca-alert--warning">
						Nenhum próximo atendimento
					</div>
				)}
			</div>

			<Button
				variant={variant}
				marginTop={24}
				block
				size="large"
				onClick={handleScheduleAttendance}
			>
				Novo agendamento
			</Button>
		</Container>
	);
}

export default PatientNextAttendances;
