import { useCompanyContext } from '../../../contexts/company';
import { App } from './AppContainer.styles';
import { useLocation } from 'react-router-dom';

import Topbar from '../Topbar';

function AppContainer({ children }) {
	const { settings } = useCompanyContext();
	const { pathname } = useLocation();

	if (!settings?.logo?.url || pathname === '/') {
		return(
			<App>
				<App.Body>
					{children}
				</App.Body>
			</App>
		)
	}

	return (
		<App>
			<Topbar companyLogo={settings?.logo?.url}/>
			<App.Body>
				{children}
			</App.Body>
		</App>
	);
}

export default AppContainer;
