import React from 'react';
import { Loading, Container, Text, Button } from '../../components';
import { OTPValidationForm as StyledOTPValidationForm } from './OTPValidationForm.styles';
import { InputOTP } from '@amigoapp/doca-react';

function OTPValidationForm({
    loading,
    isValid,
    values,
    onSubmitClick,
    onOTPChange,
    handleNavigateBackPress,
    remainingTime,
    variant,
    sendOtpCode,
    errorMessage,
	patientNumber
}) {
    if (loading) {
        return <Loading />;
    }

    return (
        <Container padded>
            <Text size="large" weight="semibold" marginBottom={16}>Validação de segurança</Text>

            <Text marginBottom={16} variant="gray">
                Enviamos um SMS com um código de validação para {patientNumber}, preencha o campo abaixo com este código para dar continuidade.
            </Text>

            <form onSubmit={onSubmitClick}>
                <StyledOTPValidationForm.InputFieldContainer>
                    <label htmlFor="otp">Código de validação</label>
                    <InputOTP
                        length={6}
                        name="otp"
                        value={values.otp}
                        onChange={onOTPChange}
                        type="tel"
                        placeholder="0"
                        error={errorMessage}
                    />
                </StyledOTPValidationForm.InputFieldContainer>

                <StyledOTPValidationForm.ValidationButton
                    className="doca-button doca-button--tertiary doca-button--block doca-button--large doca-mb-4"
                    disabled={remainingTime > 0}
                    onClick={sendOtpCode}
                >
                    {remainingTime > 0
                        ? `Aguarde ${remainingTime} segundos para enviar novamente`
                        : 'Enviar código novamente'}
                </StyledOTPValidationForm.ValidationButton>

                <Button
                    block
                    size="large"
                    disabled={!isValid}
                    marginBottom={16}
                    variant={variant}
                    type="submit"
                >
                    Continuar
                </Button>

                <Button
                    block
                    size="large"
                    onClick={handleNavigateBackPress}
                    variant='secondary'
                    type="button"
                >
                    <i className="doca-icon doca-icon__arrow-left"></i>
                    Voltar
                </Button>

            </form>
        </Container>
    );
}

export default OTPValidationForm;
