import httpClient from '../utils/httpClient';
import { getAuthToken } from '../utils/utils';

export const generateOtp = async values => {
	const { data, error } = await httpClient({
		method: 'post',
		url: `/patient-otp/generate`,
		data: values,
		headers: {
			authorization: getAuthToken()
		}
	});

	return { data, error };
};

export const validateOtp = async otpData => {
	const { data, error } = await httpClient({
		method: 'post',
		url: `/patient-otp/validate`,
		data: otpData,
		headers: {
			authorization: getAuthToken()
		}
	});

	return { data, error };
};
