import React, { useCallback } from "react";

import { Home as StyledHome } from "./Home.styles.js";

import { Loading, Carousel, EmptyState } from '../../components';
import { PlaceItem, PlanItem, DoctorItem } from '../../views';

import defaultImage from '../../assets/images/avatar.png';
import defaultBackground from "../../assets/images/background-default.png";
import { useWindowDimensions } from "../../utils/windowDimensionSize.js";

const TAB = {
	DOCTORS: "DOCTORS",
	ABOUT: "ABOUT",
	PLACES: "PLACES",
	INSURANCES: "INSURANCES",
};

function Home({
	activeTab,
	openedModal,
	setOpenedModal,
	settings,
	scheduleSettings,
	galleryImageIndex,
	loading,
	variant,
	handleSchedulePress,
	handleClickGalleryImages,
	handleChangeTab,
}) {
	const { width } = useWindowDimensions();

	const isMobile = width < 600;

	const renderPlanItem = useCallback(
		(plan) => (
			<div className="doca-mb-4">
				<PlanItem
					plan={plan}
				/>
			</div>
		), []
	);

	const renderDoctorItem = useCallback(
		(doctor) => (
			<div className="doca-mb-4">
				<DoctorItem
					doctor={doctor}
				/>
			</div>
		),[]
	);

	const renderPlaceItem = useCallback(
		(place) => (
			<div className="doca-mb-4">
				<PlaceItem
					place={place}
				/>
			</div>
		),[]
	);

	if (!settings && !loading) {
		return <EmptyState
			icon="warning"
			title="Clínica não encontrada"
			description="Verifique se o link está correto e tente novamente!"
		/>
	}

	if (!settings || !scheduleSettings || loading) {
		return <Loading />;
	}

	return (
		<>
			<StyledHome className={openedModal ? 'hidden' : ''}>
				<StyledHome.Header>
					<StyledHome.BackgroundImage src={settings?.background?.url || defaultBackground} />

					<StyledHome.CompanyInfoRow>
						<StyledHome.CompanyInfoRow.AvatarContainer>
							<StyledHome.CompanyInfoRow.Avatar className="doca-avatar doca-avatar--rounded" src={settings?.logo?.url || defaultImage} />
						</StyledHome.CompanyInfoRow.AvatarContainer>

						<StyledHome.CompanyInfoRow.Content>
							<div className="doca-flex doca-flex-col">
								<StyledHome.CompanyInfoRow.Content.Title>{settings.name}</StyledHome.CompanyInfoRow.Content.Title>
								<div className="doca-flex doca-flex-col">
									{settings?.specialty && (
										<StyledHome.CompanyInfoRow.Content.Specialty className="doca-mt-2">
											<i className="doca-icon doca-icon--regular doca-icon__user-doctor m-r-4"></i>

											{settings?.specialty}
										</StyledHome.CompanyInfoRow.Content.Specialty>
									)}
								</div>
							</div>

							{!isMobile && (
								<div className="doca-flex doca-justify-end">
									<button className={`doca-button doca-button--large doca-button--${variant}`} onClick={handleSchedulePress}>
										<i className="doca-icon doca-icon--regular doca-icon__calendar m-r-4"></i>
										Agendar atendimento
									</button>
								</div>
							)}
						</StyledHome.CompanyInfoRow.Content>

						{isMobile && (
							<button className={`doca-button doca-button--large doca-button--${variant} doca-button--block m-t-16`} onClick={handleSchedulePress}>
								<i className="doca-icon doca-icon--regular doca-icon__calendar m-r-4"></i>
								Agendar atendimento
							</button>
						)}
					</StyledHome.CompanyInfoRow>

					<div className="doca doca-w-full">
						<ul className={`doca-tabs doca-tabs--${variant} doca-w-full`} style={{ flexGrow: 1 }} >
							<li
								className={`doca-tabs__item ${activeTab === TAB.ABOUT ? 'doca-tabs__item--active' : ''}`}
								style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
								onClick={() => handleChangeTab('ABOUT')}
							>
								Sobre
							</li>

							<li
								className={`doca-tabs__item ${activeTab === TAB.DOCTORS ? 'doca-tabs__item--active' : ''}`}
								style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
								onClick={() => handleChangeTab('DOCTORS')}
							>
								Especialistas
							</li>

							<pre>{!scheduleSettings?.insurance_plans?.length}</pre>

							{scheduleSettings?.insurance_plans?.length ? (
								<li
									className={`doca-tabs__item ${activeTab === TAB.INSURANCES ? 'doca-tabs__item--active' : ''}`}
									style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
									onClick={() => handleChangeTab('INSURANCES')}
								>
									Convênios
								</li>
							) : null}
						</ul>
					</div>
				</StyledHome.Header>

				<StyledHome.BodyContainer className="doca-pb-4">
					{activeTab === 'ABOUT' && (
						<>
							<StyledHome.BodyContainer.Card className="doca-page__box">
								{settings.about && (
									<StyledHome.BodyContainer.Card>
										<StyledHome.BodyContainer.Card.Title>Resumo</StyledHome.BodyContainer.Card.Title>

										<StyledHome.BodyContainer.Card.Content>{settings.about}</StyledHome.BodyContainer.Card.Content>
									</StyledHome.BodyContainer.Card>
								)}

								{settings.specialties?.length ? (
									<StyledHome.BodyContainer.Card className="m-t-24">
										<StyledHome.BodyContainer.Card.Title>Especialidades</StyledHome.BodyContainer.Card.Title>

										<StyledHome.BodyContainer.Card.ContentWrap className="m-t-8">
											{settings.specialties.map((specialty, index) => (
												<span key={index} className={`doca-badge doca-badge--${variant} doca-badge--outline doca-mr-2 m-t-8`}>
													{specialty}
												</span>
											))}
										</StyledHome.BodyContainer.Card.ContentWrap>
									</StyledHome.BodyContainer.Card>
								) : null}

								{scheduleSettings.places?.length ? (
									<StyledHome.BodyContainer.Card className="m-t-24">
										<StyledHome.BodyContainer.Card.Title>Endereços</StyledHome.BodyContainer.Card.Title>

										<StyledHome.BodyContainer.Card className="m-t-8">
											<StyledHome.FlatList data={scheduleSettings.places} separator="default" renderItem={renderPlaceItem} />
										</StyledHome.BodyContainer.Card>
									</StyledHome.BodyContainer.Card>
								) : null}

								{settings.images?.length ? (
									<StyledHome.BodyContainer.Card className="m-t-24 m-b-40">
										<StyledHome.BodyContainer.Card.Title>Fotos</StyledHome.BodyContainer.Card.Title>

										<StyledHome.GalleryGrid images={settings.images} onImagePress={handleClickGalleryImages} />
									</StyledHome.BodyContainer.Card>
								) : null}
							</StyledHome.BodyContainer.Card>
						</>
					)}

					{activeTab === 'DOCTORS' && (
						<StyledHome.BodyContainer.Card className="doca-page__box">
							<StyledHome.FlatList data={scheduleSettings.users} separator="default" renderItem={renderDoctorItem} />
						</StyledHome.BodyContainer.Card>
					)}

					{activeTab === 'INSURANCES' && (
						<StyledHome.BodyContainer.Card className="doca-page__box">
							<StyledHome.FlatList data={scheduleSettings.insurance_plans} separator="default" renderItem={renderPlanItem} />
						</StyledHome.BodyContainer.Card>
					)}
				</StyledHome.BodyContainer>
			</StyledHome>

			{openedModal && <Carousel onClose={() => setOpenedModal(false)} images={settings.images} activeImageIndex={galleryImageIndex} />}
		</>
	);
}

export default Home;
