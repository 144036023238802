import { CardInfo as StyledCardInfo } from './CardInfo.styles';

const CardInfo = ({
	title,
	thumbUrl,
	subtitle,
	description,
	subtitleIcon,
	withoutAvatar,
	withoutIcon,
	mainIcon,
	subtitleSpacing,
	titleSize = 'base',
	titleWeight = 'semibold',
	titleVariation = 'dark',
}) => (
	<StyledCardInfo.Row>
		{!withoutAvatar && <StyledCardInfo.Avatar
			rounded
			size="medium"
			url={thumbUrl}
		/>}

		{!withoutIcon && <StyledCardInfo.DocaIcon
			iconSize="medium"
			icon={mainIcon}
		/>}

		<StyledCardInfo.Content withoutAvatar={withoutAvatar}>
			<StyledCardInfo.Row>
				<StyledCardInfo.Title variant={titleVariation} size={titleSize} weight={titleWeight}>
					{title}
				</StyledCardInfo.Title>
			</StyledCardInfo.Row>

			{!!subtitle && (
				<StyledCardInfo.Text
					flexGrow
					icon={subtitleIcon}
					size="small"
					marginTop={subtitleSpacing}
					style = {{ color : '#3D4E5C' }}
				>
					{subtitle}
				</StyledCardInfo.Text>
			)}

			{!!description && (
				<StyledCardInfo.Description type="small">
					{description}
				</StyledCardInfo.Description>
			)}
		</StyledCardInfo.Content>
	</StyledCardInfo.Row>
);

export default CardInfo;
