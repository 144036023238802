import React, { useCallback } from "react";

import { Form as StyledForm } from "./UpdateTimegridForm.styles.js";

import { Calendar, Button, Loading, FlatList } from '../../components';

import ShiftSlotItem from '../_shared/ShiftSlotItem'
import SlotItem from '../_shared/SlotItem';

function UpdateTimegridForm({
	loading,
	selectedSlot,
	listMaxHeight,
	selectedDate,
	enabledDates,
	variant,
	onSelectSlot,
	currentSlotsByUser,
	onSelectDate,
	onSubmitPress,
	handleNavigateBackPress,
	handleCancelPress,
	handleNavigateHome,
	submitText
}) {
	const renderSlotItem = useCallback(data => (
		<SlotItem data={data} selectedSlot={selectedSlot} onSelectSlot={onSelectSlot} />
	), [selectedSlot, onSelectSlot]);

	const renderShiftSlotItem = useCallback(data => (
		<ShiftSlotItem data={data} selectedSlot={selectedSlot} onSelectSlot={onSelectSlot} />
	), [selectedSlot, onSelectSlot]);

	const renderSlotKeyExtractor = useCallback((data, index) => `${index}-${data.user.id}-${selectedDate}`, [selectedDate]);

	const renderTimegridItem = useCallback((item, index) => {
		const isSlotByShift = item.slots?.[0]?.shift;

		return (
			<div key={renderSlotKeyExtractor(item, index)}>
				{isSlotByShift ? renderShiftSlotItem(item) : renderSlotItem(item)}
			</div>
		);
	}, [renderShiftSlotItem, renderSlotItem, renderSlotKeyExtractor]);

	if (loading) {
		return <Loading />
	}

	return (
		<StyledForm>
			<StyledForm.Header>
				<StyledForm.Header.BodyContainer>
					<Button variant="tertiary" marginRight={8} onClick={handleNavigateBackPress}>
						<i className="doca-icon doca-icon--large doca-icon__arrow-left" />
					</Button>

					<StyledForm.Header.Title size="large" weight="semibold">
						Data e Horário
					</StyledForm.Header.Title>
				</StyledForm.Header.BodyContainer>

				{handleNavigateHome && (
					<Button variant="tertiary" onClick={handleNavigateHome}>
						<i className="doca-icon doca-icon--large doca-icon__xmark" />
					</Button>
				)}
			</StyledForm.Header>

			<StyledForm.Body>
				{enabledDates?.length ? (
					<Calendar selectedDate={selectedDate} enabledDates={enabledDates} variant={variant} onChangeDate={onSelectDate} />
				) : null}

				<div className="doca-mt-4">
					{currentSlotsByUser.length ? (
						<FlatList
							data={currentSlotsByUser}
							renderItem={renderTimegridItem}
							renderSlotItem={renderSlotItem}
							renderShiftSlotItem={renderShiftSlotItem}
							renderKeyExtractor={renderSlotKeyExtractor}
							maxHeight={listMaxHeight}
						/>
					) : (
						<div className="doca-alert doca-alert--warning">Nenhum horário disponível.</div>
					)}
				</div>
			</StyledForm.Body>

			<Button marginTop={16} variant={variant} className="doca-mt-4" block size="large" disabled={!selectedSlot} onClick={onSubmitPress}>{submitText}</Button>
			<Button
				block
				size="large"
				onClick={handleCancelPress}
				variant='secondary'
				customClass={'doca-mt-4 doca'}
				style={{ border: '1px solid #F26161', color: '#F26161' }}
				type="button"
				marginTop={16}
			>
				<p className="doca-text--danger doca-text--normal doca-text--base"> Cancelar agendamento </p>
			</Button>
		</StyledForm>
	);
}

export default UpdateTimegridForm;
