import { useCompanyContext } from '../../../contexts/company';
import ShiftSlotItem from './ShiftSlotItem';


function ShiftSlotItemContainer({ data, selectedSlot, onSelectSlot }) {
    const { settings } = useCompanyContext();

	const getShiftLabel = shift => {
		switch (shift) {
			case 'MORNING':
				return 'Manhã';
			case 'AFTERNOON':
				return 'Tarde';
			case 'NIGHT':
				return 'Noite';
			default:
				return shift;
		}
	};

	const formattedSlots = data.slots
        .map(slot => ({
            ...slot,
            label: getShiftLabel(slot.shift)
    }));

    return (
        <ShiftSlotItem
			slotsGrouped={formattedSlots}
			user={data.user}
			variant={settings.theme?.name}
			onSelectSlot={onSelectSlot}
			selectedSlot={selectedSlot}
        />
    );
}

export default ShiftSlotItemContainer;
