import styled from 'styled-components';


export const App = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	flex: 1;
`;

App.Body = styled.div`
	margin: 0;
	display: flex;
	font-size: 16px;
	width: 100%;
	height: 100%;
	color: #676a6c;
	max-width: 600px;

	@media screen and (min-width: 601px) {
		background-color: #FFFFFF;
		margin: 0 auto;
	}
`;
