import React, { useCallback } from 'react';

import { SlotItem as StyledSlotItem } from './SlotItem.styles';

import { DoctorItem } from '../../';

const SlotItem = ({
	user,
	variant,
	slotsGrouped,
	selectedSlot,
	showMoreSlots,
	onSelectSlot,
	onShowMoreSlots,
}) => {
	const isSlotActive = useCallback(slot => slot.id === selectedSlot?.id, [selectedSlot]);

	const renderSlotButtonItem = useCallback(slot => (
		<button key={slot.id} className={`ds-flex ds-flex__item doca-shortcut doca-shortcut--small doca-justify-center w-100 ${isSlotActive(slot) ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectSlot({ ...slot, user })}>
			{slot.start}
		</button>
	), [variant, user, isSlotActive, onSelectSlot]);

	return (
		<StyledSlotItem className="doca-mb-4">
			<DoctorItem doctor={user} />

			<StyledSlotItem.SlotContainer className="doca-mt-4">
				{slotsGrouped?.MORNING?.length ? (
					<StyledSlotItem.SlotContainer.Info className="doca-mb-4">
						<StyledSlotItem.SlotContainer.Info.Turn weight="medium">Manhã</StyledSlotItem.SlotContainer.Info.Turn>

						<StyledSlotItem.SlotContainer.Info.Slots className="doca-slots__container">
							{slotsGrouped.MORNING.slice(0, !showMoreSlots ? 3 : slotsGrouped.MORNING.length).map((slot, index) => renderSlotButtonItem(slot))}
						</StyledSlotItem.SlotContainer.Info.Slots>
					</StyledSlotItem.SlotContainer.Info>
				) : null}

				{slotsGrouped?.AFTERNOON?.length ? (
					<StyledSlotItem.SlotContainer.Info className="doca-mb-4">
						<StyledSlotItem.SlotContainer.Info.Turn weight="medium">Tarde</StyledSlotItem.SlotContainer.Info.Turn>

						<StyledSlotItem.SlotContainer.Info.Slots className="doca-slots__container">
							{slotsGrouped.AFTERNOON.slice(0, !showMoreSlots ? 3 : slotsGrouped.AFTERNOON.length).map((slot, index) =>
								renderSlotButtonItem(slot)
							)}
						</StyledSlotItem.SlotContainer.Info.Slots>
					</StyledSlotItem.SlotContainer.Info>
				) : null}

				{slotsGrouped?.NIGHT?.length ? (
					<StyledSlotItem.SlotContainer.Info className="doca-mb-4">
						<StyledSlotItem.SlotContainer.Info.Turn weight="medium">Noite</StyledSlotItem.SlotContainer.Info.Turn>

						<StyledSlotItem.SlotContainer.Info.Slots className="doca-slots__container">
							{slotsGrouped.NIGHT.slice(0, !showMoreSlots ? 3 : slotsGrouped.NIGHT.length).map((slot, index) => renderSlotButtonItem(slot))}
						</StyledSlotItem.SlotContainer.Info.Slots>
					</StyledSlotItem.SlotContainer.Info>
				) : null}

				<StyledSlotItem.Button block variant="tertiary" onClick={() => onShowMoreSlots((value) => !value)}>
					{showMoreSlots ? 'Exibir menos horários' : 'Exibir mais horários'}
				</StyledSlotItem.Button>
			</StyledSlotItem.SlotContainer>
		</StyledSlotItem>
	);
};

export default SlotItem;
