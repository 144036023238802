import { React } from 'react';
import { AdvancedSelect } from '@amigoapp/doca-react';

const AdvancedSelectComponent = ({
	onChange,
	value,
	...props
}) => {

	return (
		<AdvancedSelect
			{...props}
			onChange={({ target }) => onChange && onChange(target.value)}
			value={value}
		/>
	)
};

export default AdvancedSelectComponent;
