import React, { useCallback, useState , useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import PatientExistsForm from './PatientExistsForm';
import { useCompanyContext } from '../../contexts/company';
import { patientExists } from '../../processes/patient';
import { showErrorToast } from '@amigoapp/doca-react';
import { FormatterUtils } from '../../utils';
import { clearMask } from '../../utils/formatter';

function PatientExistsFormContainer() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { settings, setInfo, form } = useCompanyContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.contact_cellphone) {
            errors.contact_cellphone = 'O telefone é um campo obrigatório.';
        }
        return errors;
    };

    const handleSubmitPress = useCallback(async values => {
        setLoading(true);

        try {
            const cleanPhone = clearMask(values.contact_cellphone);

            const { data } = await patientExists({ contact_cellphone: cleanPhone });

			setInfo(values => ({
				...values,
				form: {
					...values.form,
					patient: {
						contact_cellphone: cleanPhone
					}
				}
			}));

            if (data) {
                history.push('/otp-validation');
            } else {
                history.push('/select-event-group-payment-method');
            }
        } catch (err) {
            showErrorToast('Erro inesperado, tente novamente depois.');
        } finally {
            setLoading(false);
        }

    }, [history, setInfo]);

    const {
        handleSubmit,
        handleChange,
        isValid,
        values
    } = useFormik({
        initialValues: {
            contact_cellphone: form?.patient?.contact_cellphone ? FormatterUtils.formatPhone(form?.patient?.contact_cellphone) : '',
        },
        validate,
        validateOnMount: true,
        onSubmit: handleSubmitPress
    });

    const onChangeWithMask = useCallback((event, mask) => {
        if (event.target.value) {
            event.target.value = mask(event.target.value);
        }
        return handleChange(event);
    }, [handleChange]);

    const handleNavigateBackPress = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <PatientExistsForm
            values={values}
            loading={loading}
            onChangeWithMask={onChangeWithMask}
            isValid={isValid}
            variant={settings.theme?.name}
            onSubmitClick={handleSubmit}
            handleNavigateBackPress={handleNavigateBackPress}
        />
    );
}

export default PatientExistsFormContainer;
