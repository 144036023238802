import styled from 'styled-components';

import Text from "../../components/Text/index.js"
import Avatar from "../../components/Avatar/index.js"
import DocaIcon from '../DocaIcon/DocaIcon.js';

import { SPACING } from '../../constants/theme.js';

export const CardInfo = {};

CardInfo.Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

CardInfo.Avatar = Avatar;

CardInfo.DocaIcon = DocaIcon;

CardInfo.Content = styled.div`
	flex: 1;
	margin-left: 12px;
`;

CardInfo.Title = styled(Text)`
	margin-right: ${SPACING.small};
`;

CardInfo.Text = styled(Text)`
	margin-top: ${SPACING.extraSmall};
`;

CardInfo.Description = styled(Text)`
	margin-top: ${SPACING.extraSmall};
`;
