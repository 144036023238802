import styled from 'styled-components';

import { Avatar } from '../../../components';

export const Topbar = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
`;

Topbar.Header = styled.div`
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	justify-content: center;
	padding: 8px;

	@media (max-width: 768px) {
		justify-content: flex-start;
	}
`;

Topbar.Header.Logo = Avatar;

Topbar.Separator = styled.div`
	width: 100%;
	border-bottom: 1px solid #E5E5E5;
`;
