
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from 'react';

import {Carousel as StyledCarousel} from './Carousel.styles';

function CarouselContainer({
	images,
	onClose
}) {
    return (
		<StyledCarousel>
			<div className="ds-flex ds-flex__align--center">
				<StyledCarousel.Button variant="tertiary" onClick={onClose} marginRight={8}>
					<i className="doca-icon doca-icon__xmark doca-icon--solid doca-icon--large" />
					<p className="doca-text--dark doca-text--bold doca-text--large">Fechar</p>
				</StyledCarousel.Button>

			</div>

			<StyledCarousel.Body className="carousel__container">
				<StyledCarousel.Slider
					showStatus={false}
					showArrows={false}
					infiniteLoop
					emulateTouch
				>
					{images.map((image, index) => {
						return (
							<StyledCarousel.ImageContainer>
								<img alt={index} src={image.thumb_url} />
							</StyledCarousel.ImageContainer>
						)
					})}
				</StyledCarousel.Slider>
			</StyledCarousel.Body>
		</StyledCarousel>
	);
}

export default CarouselContainer;
