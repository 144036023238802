import styled from 'styled-components';
import { Text, Separator, Button, Container} from '../../components';

export const ScheduledPage = Container;

ScheduledPage.CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

ScheduledPage.HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

ScheduledPage.Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

ScheduledPage.FlexContainer = styled.div`
	margin-top: 24px;
`;

ScheduledPage.Text = styled(Text)`
	text-align: center;
`;

ScheduledPage.Separator = Separator;
ScheduledPage.Button = Button;

ScheduledPage.IconLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	color: #26282a;

	i {
		margin-right: 8px;
		font-weight: bold;
	}
`;

ScheduledPage.ContainerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;
ScheduledPage.InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

ScheduledPage.Label = styled.div`
	font-size: 14px;
	font-weight: bold;
`;

ScheduledPage.Content = styled.div`
	color: #4E5255;
	font-size: 14px;
`;

ScheduledPage.AddressContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`;