import React from "react";

import { Form as StyledForm } from "./SelectEventGroupAndPaymentMethodForm.styles.js";

import { AdvancedSelect, Button } from '../../components';

function SelectEventGroupAndPaymentMethodForm({
	selectedGroup,
	groups,
	variant,
	onNextStepPress,
	onSelectGroup,
	paymentMethodsAllowed : PAYMENT_METHODS_ALLOWED,
	paymentMethod,
	insuranceGroupId,
	insuranceId,
	insurances,
	showInsurances,
	insuranceGroups,
	onSelectInsuranceGroup,
	onSelectPaymentMethod,
	onSelectInsurance,
	patient
}) {
	return (
		<StyledForm>
			<StyledForm.Body>
				<div className="doca-mb-4 doca-mt-2">
					<label className="doca-steps__label">
						{patient?.id ? 'Passo 1 de 2' : 'Passo 1 de 3'}
					</label>

					<div className="doca-steps__container">
						<div className={`doca-steps doca-mr-3 doca-w-full doca-steps--current doca-steps--${variant}`} />
						<div className={`doca-steps doca-w-full doca-steps--${variant}`} />
						{patient?.id ? null : <div className={`doca-steps doca-ml-3 doca-w-full doca-steps--${variant}`} />}
					</div>
				</div>

				<StyledForm.Header.Title size="large" weight="medium">
					{patient?.id ? `Olá, ${patient.name}` : ' Agendar atendimento'}
				</StyledForm.Header.Title>
				<StyledForm.Header.Subtitle size="small" weight="normal" variant="gray" marginTop={8}>
					Escolha a modalidade e forma de pagamento para o seu atendimento
				</StyledForm.Header.Subtitle>

				<div className="doca-form__field doca-form__field--required doca-mt-4">
					<label className="doca-form__label">
						Modalidade
					</label>
					<div className="doca-shortcut__container d-block">
						{groups?.ATTENDANCE ? (
							<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.ATTENDANCE?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.ATTENDANCE)}>
								<div>
									<i className="doca-icon doca-icon--large doca-icon__location-dot" />

									<p className="doca-shortcut__title">
										Presencial
									</p>
								</div>
							</button>
						) : null}

						{groups?.TELEMEDICINE ? (
							<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.TELEMEDICINE?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.TELEMEDICINE)}>
								<div>
									<i className="doca-icon doca-icon--large doca-icon__video" />

									<p className="doca-shortcut__title">
										Online
									</p>
								</div>
							</button>
						) : null}

						{groups?.COMEBACK ? (
							<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.COMEBACK?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.COMEBACK)}>
								<div>
									<i className="doca-icon doca-icon--large doca-icon__rotate-left" />

									<p className="doca-shortcut__title">
										Retorno
									</p>
								</div>
							</button>
						) : null}
					</div>
				</div>
			</StyledForm.Body>


			<div className="doca-form__field doca-form__field--required doca-mt-4">
				<label className="doca-form__label">
					Forma de pagamento
				</label>
				<StyledForm.FlexRow className="doca-shortcut__container">
					<button
						className={`doca-shortcut w-100 ${paymentMethod === PAYMENT_METHODS_ALLOWED.PARTICULAR ? `doca-shortcut--${variant}` : ''}`}
						onClick={() => onSelectPaymentMethod(PAYMENT_METHODS_ALLOWED.PARTICULAR)}
					>
						<div>
							<i className="doca-icon doca-icon--large doca-icon__credit-card" />

							<p className="doca-shortcut__title">Particular</p>
						</div>
					</button>

					<button
						className={`doca-shortcut w-100 doca-mr-4 ${paymentMethod === PAYMENT_METHODS_ALLOWED.INSURANCE ? `doca-shortcut--${variant}` : ''}`}
						onClick={() => onSelectPaymentMethod(PAYMENT_METHODS_ALLOWED.INSURANCE)}
						hidden={!showInsurances}
					>
						<div>
							<i className="doca-icon doca-icon--large doca-icon__hospital" />

							<p className="doca-shortcut__title">Convênio</p>
						</div>
					</button>
				</StyledForm.FlexRow>
			</div>

			<StyledForm.FlexRow direction="column" className="doca-mt-4">
				{paymentMethod === PAYMENT_METHODS_ALLOWED.INSURANCE ? (
					<div>
						{insuranceGroups?.length ? (
							<div>
								<AdvancedSelect
									value={insuranceGroupId}
									label="Escolha seu convênio"
									placeholder="Selecione"
									size="large"
									required
									onChange={onSelectInsuranceGroup}
									options={[
										{
											label: 'Selecione',
											value: ''
										},
										...insuranceGroups.map((insuranceGroup) => ({
											label: insuranceGroup.name,
											value: insuranceGroup.id,
											key: insuranceGroup.id
										}))
									]}
								/>
							</div>
						) : null}

						<div className="doca-mt-4">
							<AdvancedSelect
								value={insuranceId}
								label="Escolha seu plano"
								placeholder="Selecione"
								size="large"
								required
								onChange={onSelectInsurance}
								options={[
									{
										label: 'Selecione',
										value: ''
									},
									...insurances.map((insurance) => ({
										label: insurance.name,
										value: insurance.id,
										key: insurance.id
									}))
								]}
							/>
						</div>
					</div>
				) : null}
			</StyledForm.FlexRow>

			<Button marginTop={24} variant={variant} className="doca-mt-4" block size="large" disabled={!selectedGroup || !paymentMethod} onClick={onNextStepPress}>
				Próximo

				<i className="doca-icon doca-icon--large doca-icon__arrow-right"></i>
			</Button>
		</StyledForm>
	);
}

export default SelectEventGroupAndPaymentMethodForm;
