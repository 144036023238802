import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';

import { NavigationUtils, Utils } from '../../utils'

import { findScheduleSettingsBySlug, findSettingsBySlug } from '../../processes/company';

import Home from './Home';

function HomeContainer() {
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('ABOUT');
	const [openedModal, setOpenedModal] = useState(false);
	const [galleryImageIndex, setGalleryImageIndex] = useState(false);

	const history = useHistory();

	const { settings, scheduleSettings, setInfo, clearFormAndStorage } = useCompanyContext();

	const slug = Utils.getHostSubdomain();

	const fetchCompanyInfo = useCallback(async () => {
		const filter = {
			slug
		};

		if (!filter.slug) {
			return;
		}

		clearFormAndStorage();

		setLoading(true);

		const [companySettings, companySchedulerSettings] = await Promise.all([
			findSettingsBySlug(filter),
			findScheduleSettingsBySlug(filter)
		]);

		const settingsInfo = {
			settings: companySettings?.data,
			scheduleSettings: companySchedulerSettings?.data
		};

		if (companySettings.data?.name) {
			document.title = companySettings.data.name;
		}

		setInfo(value => ({
			...value,
			...settingsInfo
		}));

		localStorage.setItem('companyInfo', JSON.stringify(settingsInfo));

		localStorage.setItem('token', companySettings?.data?.token);
		localStorage.setItem('slug', slug);

		setLoading(false);
	}, [setInfo, slug, clearFormAndStorage]);

	useEffect(() => {
		fetchCompanyInfo();
	}, [slug, fetchCompanyInfo]);

	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


	const handleClickGalleryImages = useCallback(index => {
		setOpenedModal(true);

		setGalleryImageIndex(index);
	}, [setOpenedModal, setGalleryImageIndex])

	const handleChangeTab = useCallback(tab => setActiveTab(tab), []);

	const handleSchedulePress = useCallback(() => NavigationUtils.navigate(history, '/patient-exists'), [history]);

    return (
		<Home
			loading={loading}
			openedModal={openedModal}
			setOpenedModal={setOpenedModal}
			galleryImageIndex={galleryImageIndex}
			settings={settings}
			scheduleSettings={scheduleSettings}
			activeTab={activeTab}
			variant={settings?.theme?.name}
			handleSchedulePress={handleSchedulePress}
			handleChangeTab={handleChangeTab}
			handleClickGalleryImages={handleClickGalleryImages}
		/>
	);
}

export default HomeContainer;
