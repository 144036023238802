import React from 'react';

import { CardInfo } from '../../../components';

const Plan = ({ plan }) => (
	<CardInfo
		title={plan.group_name}
		subtitle={plan.name}
		thumbUrl={plan.file.thumb_url}
		withoutIcon
	/>
);

export default Plan;
