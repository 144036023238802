import React from 'react';

import { FlatList as StyledFlatList } from './FlatList.styles'

const FlatList = ({
	data,
	maxHeight,
	renderItem
}) => {
	return (
		<StyledFlatList maxHeight={maxHeight}>
			{data?.map((item, index) => {
				if (renderItem) {
					return renderItem(item, index);
				}

				return (
					<div key={index}>
						{item.name}
					</div>
				)
			})}
		</StyledFlatList>
	)
};

export default FlatList;
