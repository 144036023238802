import React from 'react';

import { CardInfo } from '../../../components';

const Place = ({ place, titleSize, titleVariation, titleWeight = 'medium' }) => (
	<CardInfo
		withoutAvatar
		subtitle={place.address || 'Sem endereço'}
		title={place.name}
		mainIcon="location-dot"
		titleWeight={titleWeight}
		titleVariation={titleVariation}
		titleSize={titleSize}
		subtitleSpacing={4}
	/>
);

export default Place;
