import React from 'react';
import { Topbar as StyledTopbar } from './Topbar.styles';
import { useLogoNavigation } from './TopBarContainer';

export const Topbar = ({ companyLogo }) => {
    const { navigateToHome } = useLogoNavigation();

    return (
        <StyledTopbar>
            <StyledTopbar.Header>
                <div
                    onClick={navigateToHome}
                    role="button"
                    style={{ cursor: 'pointer' }}
                >
                    <StyledTopbar.Header.Logo
                        rounded
                        size="large"
                        url={companyLogo}
                    />
                </div>
            </StyledTopbar.Header>
            <StyledTopbar.Separator />
        </StyledTopbar>
    );
};
