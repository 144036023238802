import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { NavigationUtils } from '../../utils';

import SelectEventGroupAndPaymentMethodForm from './SelectEventGroupAndPaymentMethodForm';

const PAYMENT_METHODS_ALLOWED = {
	PARTICULAR: 'PARTICULAR',
	INSURANCE: 'INSURANCE'
};

function SelectEventGroupAndPaymentMethodFormContainer() {
	const { scheduleSettings, form, settings, setInfo } = useCompanyContext();
	const [group, setGroup] = useState(form?.event_group);
	const [paymentMethod, setPaymentMethod] = useState(form?.payment_method);
	const [insuranceGroupId, setInsuranceGroupId] = useState(form?.insurance?.health_insurance_group_id);
	const [insuranceId, setInsuranceId] = useState(form?.insurance?.id);

	const history = useHistory();
	const insurances = useMemo(() => scheduleSettings?.insurance_plans?.filter(insurance => !insuranceGroupId || insurance.health_insurance_group_id === ~~insuranceGroupId), [insuranceGroupId, scheduleSettings?.insurance_plans]);

	const handleChangePaymentMethod = useCallback(payment => {
		if (payment === PAYMENT_METHODS_ALLOWED.PARTICULAR) {
			setInsuranceId('');
			setInsuranceGroupId('');
		}
	}, [setInsuranceId, setInsuranceGroupId]);

	useEffect(() => {
		handleChangePaymentMethod(paymentMethod);
	}, [paymentMethod, handleChangePaymentMethod])

	const handleNextStepPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				event_group: group,
				insurance_group: insuranceGroupId ? scheduleSettings.insurance_groups.find(item => item.id === ~~insuranceGroupId) : null,
				payment_method: paymentMethod,
				insurance: insurances?.find(item => item.id === ~~insuranceId)
			}
		}));

		NavigationUtils.navigate(history, '/select-timegrid');
	}, [history, setInfo, group, insurances, insuranceId, paymentMethod, insuranceGroupId, scheduleSettings.insurance_groups]);

	const handleNavigateBackPress = useCallback(() => {
		if (group) {
			setInfo(values => ({
				...values,
				form: {
					...values.form,
					event_group: null,
					insurance_group: null,
					payment_method: null,
					insurance: null
				}
			}));
		}

		NavigationUtils.goBackHome(history);
	}, [history, group, setInfo]);

    return (
		<SelectEventGroupAndPaymentMethodForm
			selectedGroup={group}
			groups={scheduleSettings?.event_groups}
			variant={settings.theme?.name}
			onNextStepPress={handleNextStepPress}
			onSelectGroup={setGroup}
			handleNavigateBackPress={handleNavigateBackPress}
			paymentMethodsAllowed={PAYMENT_METHODS_ALLOWED}
			paymentMethod={paymentMethod}
			insuranceGroupId={insuranceGroupId}
			insuranceId={insuranceId}
			insurances={insurances}
			showInsurances={!!scheduleSettings?.insurance_plans?.length}
			insuranceGroups={scheduleSettings?.insurance_groups}
			onSelectInsuranceGroup={setInsuranceGroupId}
			onSelectPaymentMethod={setPaymentMethod}
			onSelectInsurance={setInsuranceId}
			patient={form?.patient}
		/>
	);
}

export default SelectEventGroupAndPaymentMethodFormContainer;
