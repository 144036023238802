import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { GoogleUtils, NavigationUtils } from '../../utils';
import { useCompanyContext } from '../../contexts/company';
import { findAttendanceByToken } from '../../processes/attendance';

import ScheduledSucessfully from './ScheduledSucessfully';
import { showErrorToast } from '@amigoapp/doca-react';

function ScheduledSucessfullyContainer() {
	const query = useQuery();
	const history = useHistory();

	const { externalPagesToken, form, settings } = useCompanyContext();

	const externalToken = useMemo(() => query.get('token') || externalPagesToken, [query, externalPagesToken]);

	const [attendance, setAttendance] = useState(null);
	const [attendanceStatus] = useState('CONFIRMED');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const attendanceInfo = useMemo(() => ({
		start_date: attendance?.start_date || form?.start_date,
		end_date: attendance?.end_date || form?.end_date,
		user: attendance?.doctor || form?.slot?.user,
		place: attendance?.place || form?.slot?.place,
		event_group: attendance?.event_group || form?.slot?.event_group,
		event: attendance?.event || form?.slot?.event,
		allowedToEdit: !attendance?.done && !attendance?.confirmed_at && !attendance?.arrived && !attendance?.canceled && attendanceStatus === 'CONFIRMED',
		arrival_start_time: attendance?.arrival_start_time,
		arrival_end_time: attendance?.arrival_end_time,
		insurance: attendance?.insurance,
	}), [attendance, attendanceStatus, form]);

	const [loading, setLoading] = useState(true);

	const googleCalendarUrl = useMemo(() => GoogleUtils.getCalendarUrl(attendanceInfo), [attendanceInfo]);
	const googleMapsDirectionUrl = useMemo(() => GoogleUtils.getDirectionUrl(form?.place?.address), [form?.place?.address]);

	const getAttendance = useCallback(async () => {
		const filter = { token: externalToken };

		if (!filter.token) {
			return;
		}

		setLoading(true);

		const { data, error } = await findAttendanceByToken(filter);

		if (error) {
			showErrorToast('Não foi possível buscar as informações do agendamento.');
		} else {
			setAttendance(data);
		}

		setLoading(false);
	}, [externalToken]);

	const handleDirectionPress = useCallback(() => {
		window.open(googleMapsDirectionUrl, '_blank');
	}, [googleMapsDirectionUrl]);

	useEffect(() => {
		getAttendance();
	}, [getAttendance, externalToken]);

	const handleAgendaPress = useCallback(() => {
		window.open(googleCalendarUrl, '_blank');
	}, [googleCalendarUrl]);

	const handleEditAttendancePress = () => NavigationUtils.navigate(history, `/update-timegrid/${externalToken}`);

	return (
		<ScheduledSucessfully
			loading={loading}
			attendance={attendanceInfo}
			settings={settings}
			attendanceStatus={attendanceStatus}
			googleCalendarUrl={googleCalendarUrl}
			googleMapsDirectionUrl={googleMapsDirectionUrl}
			handleEditAttendancePress={handleEditAttendancePress}
			variant={settings.theme?.name}
			handleSaveAgendaPress={handleAgendaPress}
			handleDirectionPress={handleDirectionPress}
		/>
	);
}

export default ScheduledSucessfullyContainer;
